<template>
  <div>
    <div class="row">
      <div class="col mb-3">
        <div class="form-inline justify-content-end">
          <b-form-select
              v-model="analyticsQuery"
              class="analytics-filter"
              size="md"
              :options="['Last 7 Days', 'Last 30 Days', 'Last 90 Days' ,'Lifetime']"
              @change="getAnalytics"
          ></b-form-select>
        </div>
      </div>
    </div>
    <div v-if="!loadingBusinesses && loadingAnalytics" class="text-center">
      <b-spinner class="align-middle mb-3"></b-spinner>
    </div>
    <div v-else-if="analytics !== null" class="row">
      <div class="col-sm-4">
        <div class="card text-center rounded-lg">
          <div class="row">
            <div class="col pb-3">
              <div class="stat-head">Total Mailings</div>
              <div class="big-numbers text-darker-blue">{{analytics.total_mailings}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="card text-center rounded-lg">
          <div class="row">
            <div class="col pb-3">
              <div class="stat-head">QR Scanned</div>
              <div class="big-numbers text-darker-blue">{{analytics.total_qr}}</div>
            </div>
            <div class="col pb-3">
              <div class="stat-head">Conversion Rate</div>
              <div class="big-numbers smaller">{{analytics.percent_qr}}%</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="card text-center rounded-lg">
          <div class="row">
            <div class="col pb-3">
              <div class="stat-head">Total Leads</div>
              <div class="big-numbers text-darker-blue">{{analytics.total_leads}}</div>
            </div>
            <div class="col pb-3">
              <div class="stat-head">Conversion Rate</div>
              <div class="big-numbers smaller">{{analytics.percent_leads}}%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card rounded-lg">
          <div class="card-body">
            <template v-if="!loadingBusinesses">
              <div class="font-weight-bold mb-3">Recent Prospects & Leads</div>
              <div class="table-responsive mb-0 customer-table">
                <div v-if="leads.length" class="tablerounededCorner">
                  <b-table
                      :items="leads"
                      :fields="datatable.columns"
                      :sort-by.sync="datatable.queries.sort_by"
                      :sort-desc.sync="datatable.queries.sort_desc"
                      :per-page="datatable.queries.per_page"
                      data-export-types="['csv']"
                      :no-local-sorting="true"
                      :striped="true"
                      thead-class="border-0"
                      class="roundedTable"
                      @sort-changed="sortChanged"
                  >
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                    </div>
                    <template v-slot:cell(status)="data">
                      <span class="font-weight-bold" :style="'color:' + statusColor(data.item.status)">{{data.item.status}}</span>
                    </template>
                    <template v-slot:cell(connected)="data">
                      <span v-if="data.item.location" class="text-success font-size-20"><i class="uil uil-check" /></span>
                      <span v-else class="text-danger font-size-20"><i class="uil uil-times" /></span>
                    </template>
                    <template v-slot:cell(actions)="data">
                      <a :href="data.item.google_maps_url" class="mr-1" target="_blank"><img style="max-height: 30px" src="https://review-link.sfo3.cdn.digitaloceanspaces.com/Icon.png"></a>
                      <router-link
                          :to="{
                        name: 'agency.campaign-lead',
                        // eslint-disable-next-line vue/camelcase
                        params: { id: campaign_id, lead: data.item.id },
                      }"
                          class="btn btn-success btn-sm ml-1"
                      >
                        View
                      </router-link>
                    </template>
                  </b-table>
                </div>
                <div v-else class="text-center">No Recent Leads</div>
              </div>
              <div v-if="leads.length" class="row mt-4">
                <div class="col">

                  <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <router-link
                        :to="{
                        name: 'agency.all-lm-leads',
                      }"
                        class="btn btn-sm btn-dark-blue"
                    >
                      See All
                    </router-link>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components:{
  },
  data() {
    return {
      json_export_labels:{

      },
      datatable: {
        items: [],
        totalItems: 1,
        columns: [
          { key: 'small_date', label: 'Date', sortable: false },
          { key: 'business_name', label: 'Business', sortable: false },
          { key: 'full_name', label: 'Name', sortable: false },
          { key: 'email', label: 'Email', sortable: false },
          { key: 'phone_number', label: 'Phone', sortable: false },
          { key: 'status', label: 'Status', sortable: false },
          { key: 'actions', label: 'G Link', class:'actions lm-dashboard', width: 100  },
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'All',
          search: null,
          sort_by: 'id',
          sort_desc: true,
        },
      },
      csvLabels:{
        first_name: { title: 'First name' },
        last_name: { title: 'Last name' },
        email: { title: 'Email' },
        phone_number: { title: 'Phone number' },
        business_name: { title: 'Business name' },
        business_phone: { title: 'Business phone' },
        business_website: { title: 'Business website' },
        address: { title: 'Address' },
        city: { title: 'City' },
        state: { title: 'State' },
        zip: { title: 'ZIP' },
        country: { title: 'Country' },
        place_id: { title: 'Place ID' },
        types: { title: 'Types' },
        google_maps_url: { title: 'Google Maps URL' },
        lat: { title: 'Latitude' },
        lng: { title: 'Longitude' },
        status: { title: 'Status' },
        unique_id: { title: 'Unique ID' },
        rating: { title: 'Rating' },
        review_count: { title: 'Review count' },
        scanned: { title: 'Scanned' },
        mailed: { title: 'Mailed' },
        is_lead: { title: 'Is lead' },
        created_at: { title: 'Created at' },
        updated_at: { title: 'Updated at' },
        full_name: { title: 'Full name' },
        primary_category: { title: 'Primary category' },
        pretty_phone: { title: 'Pretty phone' },
      },
      selectedBusiness: null,
      loadingBusinesses: false,
      loadingImpersonate: false,
      loadingRemove: null,
      createIsBusy:false,
      showCreateLeadModal:false,
      campaign_id:null,
      analyticsQuery:'Last 30 Days',
      analytics:null,
      loadingAnalytics:false,
      leads:[]
    }
  },

  computed: {

    reload() {
      return this.$store.getters['leads/reload']
    },

    user() {
      return this.$store.getters['auth/user']
    },

    routeName(){
      return this.$route.name;
    },
  },

  watch: {
    reload: (val) => {
      console.log(val)
    },
  },

  created() {
    this.campaign_id = this.$route.params.id;
    this.$store.dispatch('leads/setCurrentPage', 'index')
  },

  mounted() {
    this.getBusinesses();
    this.getAnalytics();
  },

  methods: {
    getAnalytics() {
      this.loadingAnalytics = true
      this.$store
          .dispatch('leads/getAnalytics', {
            query: this.analyticsQuery,
            agencyId: this.user.agency.id
          })
          .then((res) => {
            this.analytics = res
            this.loadingAnalytics = false
          })
          .catch(() => {
            this.loadingAnalytics = false
          })
    },
    statusColor(status){
      let color = '';
      switch(status){
        case 'Lead':
          color = '#548235';
          break;
        case 'Mailed':
          color = '#284373';
          break;
        case 'Scanned':
          color = '#00b050';
          break;
      }
      return color;
    },
    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.page = 1
      this.getBusinesses()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getBusinesses()
    },

    getBusinesses() {
      this.loadingBusinesses = true
      this.$store
          .dispatch('leads/getAllLeads', {
            agencyId: this.user.agency.id,
            params:{
              limit:7
            }
          })
          .then((res) => {
            this.leads = res.data;
            this.loadingBusinesses = false
          })
          .catch(() => {
            this.loadingBusinesses = false
          })
    },
  },
}
</script>
<style lang="scss" scoped>
.status-filter{
  text-transform: capitalize;
}
.big-numbers{
  font-size: 3.5em;
  font-weight: bold;
  line-height: 1.25em;
  &.smaller{
    margin-top: 7px;
    font-size: 2.5em;
    color:#548235;
  }
  small{
    color: #737373;
    font-size: 14px;
  }
  i.uil{
    font-size: .75em;
    margin-left: -15px;
  }
}
.stat-head{
  margin-top: 10px;
  font-weight: bold;
  color: #737373;
  font-size: 1.4em;
}
.stat-foot{
  color: #17244d;
  font-size: 1.3em;
  margin-bottom: 10px;
}
.text-darker-blue{
  color: #17244d;
}

</style>
<style>
.actions.lm-dashboard{
  width: 100px;
}
.pac-container.pac-logo {
  z-index: 10000000;
}
.customer-table td.actions {min-width:200px; max-width: 200px;}
</style>
